require('./bootstrap');

require('alpinejs');


// Search
let search = document.getElementById('search');
let search__content = document.getElementById('search__content');
let search__close = document.getElementById('search__close');

search.addEventListener('click', function (evt) {
    search__content.classList.add('show');
})

search__close.addEventListener('click', function (evt) {
    search__content.classList.remove('show');
})



// burger
let mobile__burger = document.getElementById('mobile__burger');
let mobile__menu = document.getElementById('mobile__menu');
let theBody = document.querySelector('body');

mobile__burger.addEventListener('click', function (evt) {
    mobile__menu.classList.toggle('show');
    mobile__burger.classList.toggle('show');
    theBody.classList.toggle('over');
});



// weather
function askWeater() {
    if (!sessionStorage.getItem('weatherLat')) {
        navigator.geolocation.getCurrentPosition(function (position) {
            let lat = position.coords.latitude;
            let long = position.coords.longitude;

            lat = lat.toFixed(6);
            long = long.toFixed(6);

            sessionStorage.setItem('weatherLat', lat);
            sessionStorage.setItem('weatherLon', long);
            document.cookie = "weather=" + lat + '|' + long + "; path=/";
        });
    }
}

document.onreadystatechange = function () {
    if (document.readyState == "complete") {
        askWeater();
    }
}

